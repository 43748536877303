.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 600px) {
  .App-header {
    padding: 10px;  /* Further reduce padding for mobile devices */
    font-size: 20px;  /* Adjust font size for smaller screens */
  }
}

.App-header {
  padding: 20px;    /* Reduced padding */
  margin: 0 auto;   /* Center the header and remove any large margin */
  text-align: center;
  background-color: #ffffff;  /* Example background color */
  color: white;
  font-size: 24px;
}


.App-link {
  color: #61dafb;
}

.app-layout {
  display: flex !important;
  min-height: 100svh;
  width: 100%;
}


.app-content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;/* Adjust as needed */
  transition: margin-left 0.3s ease;
  height: 100svh;
  display: flex;
  flex-direction: column;
}

.session-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.controls {
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid #dee2e6;
  background-color: #f8f9fa;
  max-width: 600px;
  width: 100%;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 400px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 12px;
  max-width: 60%;
  word-wrap: break-word;
}

.message.user {
  align-self: flex-end;
  background-color: #daf8cb;
  color: #000;
}

.message.agent {
  align-self: flex-start;
  background-color: #f1f0f0;
  color: #000;
}

.message.loading {
  align-self: center;
  background-color: transparent;
  font-style: italic;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}

.input-textarea {
  flex-grow: 1;
  height: auto;
  min-height: 40px;
  margin-right: 10px;
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
  resize: none;
  overflow: hidden;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
}

.video-box {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
}

.stream {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.session-manager button {
  margin: 10px;
}

.error-message {
  color: red;
}

.card-clickable {
  transition: transform 0.1s ease;
}

.card-clickable:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.card-selected {
  border: 2px solid #007bff;
}

.g-recaptcha-badge {
  font-size: 0.7em;
  color: #777;
  text-align: left;
  margin-top: 1rem;
}
.g-recaptcha-badge a {
  color: #777;
  text-decoration: none;
}
.g-recaptcha-badge a:hover {
  text-decoration: underline;
}

.recording-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  animation: pulse 1s infinite;
}

.beta-badge {
  display: inline-block;
  background-color: #f0ad4e; /* bootstrap warning color or choose your own */
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.2em 0.5em;
  border-radius: 10px;
  text-transform: uppercase;
}

.hover-border {
  border: 1px solid transparent;
  transition: border-color 0.3s;
}

.hover-border:hover {
  border-color: #007bff;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulseAndFade {
  0%, 100% {
      transform: scale(1);
      opacity: 0.5;
  }
  50% {
      transform: scale(1.1);
      opacity: 1;
  }
}